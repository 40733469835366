@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'components/alpine.css';
@import '../../node_modules/tiny-slider/dist/tiny-slider.css';
@import '../../node_modules/@fancyapps/ui/dist/fancybox.css';
@import 'components/fonts.css';
@import 'components/active.css';
@import 'components/buttons.css';
@import 'components/input.css';
@import 'components/user.css';
@import 'components/fancybox.css';
@import 'components/youtube.css';
@import 'components/stories.css';
@import 'components/cart.css';
@import 'components/catalog.css';
@import 'tailwindcss/utilities';

* {
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

main {
    flex: 1;
}

.\!opacity-100 {
    opacity: 100% !important;
}

.\!scale-100 {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
}

.dots {
    background-image: linear-gradient(to right, #C1C1C1 26%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 9px 1px;
    background-repeat: repeat-x;
}

.dv-slider {
    width: 90%;
    margin: 0 0 0 auto;
}

.dv-slider-img {
    height: 35vw;
}

.dv-product-info {
    min-height: 35vw;
}

.dv-product-modifications-table {
    min-height: 400px;
}

.newpost_courier span.select2,
.ukrposhta_standart span.select2 {
    width: 100% !important;
}

.dv-order-newpost-courier-item {
    width: 49%;
}

#banner-items-ow {
    display: flex;
    flex-direction: column;
}

#banner-items-ow .tns-nav {
    order: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
}

#banner-items-ow button {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: #2A3335;
    margin-right: 0.5rem;
    transition: .3s;
    outline: none !important;
}

#banner-items-ow button:last-child {
    margin-right: 0;
}

#banner-items-ow button.tns-nav-active {
    background: #F9A800;
}

#banner-items {
    height: 34.63vw;
    overflow: hidden;
}

#banner-wrapper {
    overflow: hidden;
}

#banner-wrapper #banner-items-control button.prev {
    left: -40px;
    transition: all 0.3s;
}

#banner-wrapper:hover #banner-items-control button.prev  {
    left: 40px;
}

#banner-wrapper #banner-items-control button.next {
    right: -40px;
    transition: all 0.3s;
}

#banner-wrapper:hover #banner-items-control button.next {
    right: 40px;
}
.opacity-40 {
    opacity: 40%;
}


@media screen and (max-width: 768px) {
    .dv-loading-svg {
        transform: translateX(-50%);
    }

    .dv-loading {
        z-index: 100;
        background: black;
        opacity: 0.3;
        top: -10vh;
        left: -10%;
        height: 110vh;
        width: 110%;
    }
    .dv-breadcrumbs-svg {
        margin: 0 5px;
    }

    .dv-scrollbar-hidden::-webkit-scrollbar {
        opacity: 0;
    }

    .dv-scrollbar-width-0::-webkit-scrollbar {
        width: 0;
    }

    #banner-items {
        height: 100vw;
        overflow: hidden;
    }

    #item-main-image-m-ow {
        display: flex;
        flex-direction: column;
    }

    #item-main-image-m-ow .tns-nav {
        order: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
    }

    #item-main-image-m-ow button {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background: #2A3335;
        margin-right: 0.5rem;
        transition: .3s;
        outline: none !important;
    }

    #item-main-image-m-ow button:last-child {
        margin-right: 0;
    }

    #item-main-image-m-ow button.tns-nav-active {
        background: #F9A800;
    }
}

@media screen and (max-width: 1023px) {

    .dv-order-form {
        display: flex;
        flex-wrap: wrap;
    }

    .dv-order-full,
    .dv-order-form h2 {
        width: 100%;
    }

    .dv-order-checked {
        width: 3%;
    }

    .dv-order-label {
        width: 90%;
    }

    button.no-validate:hover {
        background: #F9A800;
        color: #374345;
    }
}

@media screen and (max-width: 374px) {

    .dv-order-checked {
        width: 3%;
    }

    .dv-order-label {
        width: 85%;
    }
    .dv-order-newpost-courier-item {
        width: 49%;
    }

}

@media screen and (max-width: 374px) {

    .dv-price-border {
        border: none;
    }

    .dv-price-padding {
        padding-left: 0;
        padding-right: 0;
    }
}

.compare-item-block {
    width: 150px;
    flex-shrink: 0;
    display: flex !important;
}

@media (min-width: 40em) {
    .compare-item-block {
        width: 230px;
    }
}

@media (min-width: 64em) {
    .compare-item-block {
        width: 198px;
    }
}

.digit {
    box-shadow: 0 0.1em 0.05em #e9e9e9 inset, 0 -0.1em 0.05em #e9e9e9 inset;
}

.digit>div {
    margin-top: -1px;
}

.digit-roll-in {
    animation: rollInCountdown 0.5s ease-in-out;
}

.next-digit-fade {
    animation: fadeCountdown 0.5s ease-in-out;
}

.prev-digit-fade {
    animation: fadeCountdown 0.5s ease-in-out reverse;
}

@keyframes rollInCountdown {
    from { transform: translateY(-100%); }
    to { transform: translateY(0); }
}

@keyframes fadeCountdown {
    from { opacity: 0; }
    to { opacity: 1; }
}


.product-minus-count.icon {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 2px;
        background: #007577;
    }
    &:hover {
        &:before {
            background: #F9A800;
        }
    }
}


.order-card .card-open {
    display: none;
}

.order-card.shadow .card-open {
    display: grid;
}

/* START RIGHT BOTTOM CHAT BUTTON */

.chatbtn-border {
    transform: translate(-50%, -50%);
    border: 8px solid rgba(224, 165, 38, 0.5);
    opacity: 100%;
}

@keyframes chatbtnborder {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 100%;
    }
    50% {
        transform: translate(-50%, -50%) scale(4);
        border: 2px solid rgba(224, 165, 38, 0.5);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }
}

.chatbtns.hided .chatbtn-close .chatbtn-border {
    animation: chatbtnborder 1.5s linear;
}

.chatbtns.active .chatbtn-close .chatbtn-border {
    animation: chatbtnborderback 1s;
}

.chatbtns.hided .chatbtn-open .chatbtn-border {
    animation: chatbtnborderback 1s;
}

.chatbtns.active .chatbtn-open .chatbtn-border {
    animation: chatbtnborder 1.5s linear;
}

@keyframes chatbtnborderback {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 100%;
    }
}

.chatbtns.active .chatbtn-close .chatbtn-border,
.chatbtns.hided .chatbtn-open .chatbtn-border {
    animation: chatbtnborderback 1s;
}

.chatbtns.active .chatbtn-open .chatbtn-border,
.chatbtns:not(.active) .chatbtn-close .chatbtn-border {
    opacity: 0;
}

@keyframes chatbtnicon {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.chatbtns.active .chatbtn-close-icon,
.chatbtns.hided .chatbtn-open-icon {
    animation: chatbtnicon 1s linear;
}

/* END RIGHT BOTTOM CHAT BUTTON */
